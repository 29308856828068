import request from './request'

export function information_page(data) {
  return request({
    url: '/web/information/v1/information_page',
    method: 'post',
    data
  })
}
/**
 * 门锁详情
 */
 export function information_info(data) {
  return request({
    url: '/web/information/v1/information_info',
    method: 'post',
    data
  })
}