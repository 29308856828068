<template>
  <div class="user">
    <div class="header">
      <div class="header-img">
        <div style="display: flex;" @click="jumpToHome">
          <div>
            <img style="width:0.75rem;height:0.75rem;object-fit:cover;" src="../../static/images/home-b-t.png" alt="">
          </div>
          <div style="margin-top: 0.1rem;">
            <img style="width:1.47rem;height:0.49rem;object-fit:cover;vertical-align: super;margin-left: 0.1rem;" src="../../static/images/home-b-a.png" alt="">
          </div>
        </div>
        <div class="right" @click="jumpToHome1">
          <img style="width:0.68rem;height:0.57rem;object-fit:cover;" src="../../static/images/home-s-b.png" alt="">
        </div>
      </div>
      <div class="header-text">THE SOLUTION</div>
      <div class="sub-text">{{$t('m.business_1')}}</div>
    </div>
    <div class="wrap">
      <!-- 智能门锁 -->
      <div class="door">
        <div class="door-title">
          <div class="title">{{$t('m.business.tbs_1')}}</div>
          <div class="title-bg">
            <div class="icon-door"></div>
          </div>
        </div>
        <div class="door1-box">
          <img class="door-img" :src="currentLockImg" alt="">
          <div class="scroll-box1">
            <v-touch @swipeleft="swiperleft"  @swiperight="swiperright" class="wrapper">
              <div class="scroll-box2">
                <div class="blocks-box" :style="{transform: `translateX(${translateWidth}rem)`}">
                  <div class="block" :class="{'activeBlock':currentLockIndex == index}"  v-for="(item, index) in lockList" :key="item.id" @click="handleLockClick(index)">
                    <img class="min-lock-img" :src="item.coverUrlName" alt="">
                    <div class="block-right">
                      <p style="font-size: 0.27rem;color: #27201B;margin-top: .27rem; text-align: left;">{{item.infoLabel}}</p>
                      <div class="deco"></div>
                      <p style="color: #27201B;font-weight: bold; text-align: left;font-size: 0.4rem;white-space: nowrap;text-overflow: ellipsis; overflow: hidden;">{{item.infoTitle}}</p>
                      <div class="go-detail" @click="goDetail(item)">
                        查看详情 >>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </v-touch>
          </div>
        </div>
      </div>
      <!-- 智能门禁机 -->
      <div class="door" style="margin-top: 2.3rem;padding-bottom: 1.24rem;">
        <div class="door-title">
          <div class="title">{{$t('m.business.tbs_2')}}</div>
          <div class="title-bg" style="width: 4.5rem;">
            <div class="icon-door2"></div>
          </div>
        </div>
        <div class="door1-box">
          <img class="door-img" :src="currentDoorImg" alt="">
          <div class="scroll-box1">
            <v-touch @swipeleft="swiperleft2"  @swiperight="swiperright2" class="wrapper">
              <div class="scroll-box2">
                <div class="blocks-box" :style="{transform: `translateX(${translateWidth2}rem)`}">
                  <div class="block" :class="{'activeBlock':currentDoorIndex == index}" v-for="(item, index) in doorList" :key="item.id" @click="handleDoorClick(index)">
                    <img class="min-lock-img" :src="item.coverUrlName" alt="">
                    <div class="block-right">
                      <p style="font-size: 0.27rem;color: #27201B;margin-top: .27rem; text-align: left;">{{item.infoLabel}}</p>
                      <div class="deco"></div>
                      <p style="color: #27201B;font-weight: bold; text-align: left;font-size: 0.4rem;white-space: nowrap;text-overflow: ellipsis; overflow: hidden;">{{item.infoTitle}}</p>
                      <div class="go-detail" @click="goDetail2(item)">
                        查看详情 >>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </v-touch>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { information_page } from '@/api/face'
  export default {
    name: 'User',
    data() {
      return {
        translateWidth: 0,
        translateWidth2: 0,
        currentLockIndex: 0,
        currentDoorIndex: 0,
        currentLockImg: '',
        currentDoorImg: '',
        lockList: [],
        doorList: []
      }
    },
    created() {
      information_page({
        infoType: 'info_lock',
        pageNum: 1,
        pageSize: 20
      }).then(res => {
        if(res.code == '0') {
          this.lockList = res.data.list
          this.currentLockImg = res.data.list[0].coverUrlName
        }
      })
      information_page({
        infoType: 'info_door',
        pageNum: 1,
        pageSize: 20
      }).then(res => {
        if(res.code == '0') {
          this.doorList = res.data.list
          this.currentDoorImg = res.data.list[0].coverUrlName
        }
      })
    },
    methods: {
      jumpToHome() {
        this.$router.push('/')
      },
      jumpToHome1() {
        this.$router.push('/home2')
      },
      swiperleft() {
        if (this.currentLockIndex === this.lockList.length-1) {
          return
        }
        this.currentLockIndex++
        if (this.currentLockIndex === this.lockList.length-1) {
          this.translateWidth = -6.65 * this.currentLockIndex + 3
        } else {
          this.translateWidth = -6.65 * this.currentLockIndex
        }
        this.currentLockImg = this.lockList[this.currentLockIndex].coverUrlName
      },
      swiperright() {
        if (this.currentLockIndex === 0) {
          return
        }
        this.currentLockIndex--
        this.translateWidth = -6.65 * this.currentLockIndex
        this.currentLockImg = this.lockList[this.currentLockIndex].coverUrlName
      },
      swiperleft2() {
        if (this.currentDoorIndex === this.doorList.length-1) {
          return
        }
        this.currentDoorIndex++
        if (this.currentDoorIndex === this.doorList.length-1) {
          this.translateWidth2 = -6.65 * this.currentDoorIndex + 3
        } else {
          this.translateWidth2 = -6.65 * this.currentDoorIndex
        }
        this.currentDoorImg = this.doorList[this.currentDoorIndex].coverUrlName
      },
      swiperright2() {
        if (this.currentDoorIndex === 0) {
          return
        }
        this.currentDoorIndex--
        this.translateWidth2 = -6.65 * this.currentDoorIndex
        this.currentDoorImg = this.doorList[this.currentDoorIndex].coverUrlName
      },
      handleLockClick(index) {
        if (index === this.lockList.length - 1) {
          this.translateWidth = -6.65 * index + 3
        } else {
          this.translateWidth = -6.65 * index
        }
        this.currentLockIndex = index
        this.currentLockImg = this.lockList[index].coverUrlName
      },
      handleDoorClick(index) {
        if (index === this.doorList.length - 1) {
          this.translateWidth2 = -6.65 * index + 3
        } else {
          this.translateWidth2 = -6.65 * index
        }
        this.currentDoorIndex = index
        this.currentDoorImg = this.doorList[index].coverUrlName
      },
      goDetail(item) {
        this.$router.push({path: '/userDetails', query: {id: item.id}})
      },
      goDetail2(item) {
        this.$router.push({path: '/doorDetails', query: {id: item.id}})
      },
    }
  }
</script>

<style lang="scss" scoped>
.header{
  position: relative;
  padding: 0.3rem 0.4rem 0 0.4rem;
  box-sizing: border-box;
  width: 100%;
  height: 6.05rem;
  background: url('../../static/images/mxzq.png');
  background-size: 100% 100%;
}
.header-img{
  display: flex;
  justify-content: space-between;
}
.header-text{
  width: 6.6rem;
  position: absolute;
  margin-left: -3.2rem;
  top: 38%;
  left: 50%;
  font-size: 0.56rem;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #FFFFFF;
  letter-spacing: 6px;
}
.sub-text{
  position: absolute;
  font-size: 0.27rem;
  width: 6.2rem;
  font-weight: 300;
  color: #FFFFFF;
  opacity: 0.7;
  top: 50%;
  left: 50%;
  margin-left: -3.1rem;
  margin-top: 0.2rem;
}
.wrap{
  padding-top: 0.9rem;
  background: #F9F9F9;
  padding-left: 0.44rem;
  box-sizing: border-box;
}
.door{
  padding-top: 0.9rem;
  box-sizing: border-box;
}
.icon-door{
  position: absolute;
  right: 0;
  top: -0.3rem;
  width: 0.56rem;
  height: 0.59rem;
  background: url('../../static/images/door.png');
  background-size: 100% 100%;
}
.icon-door2{
  position: absolute;
  right: .1rem;
  top: -0.3rem;
  width: 0.56rem;
  height: 0.59rem;
  background: url('../../static/images/menjinji.png');
  background-size: 100% 100%;
}
.door-title{
  position: relative;
}
.title{
  position: absolute;
  bottom: 0;
  left: 0;
  font-size: 0.56rem;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #27201B;
  z-index: 10;
}
.title-bg{
  z-index: 1;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 4rem;
  height: 0.34rem;
  background:rgba(255, 171, 10, 1);;
}
.door1-box{
  position: relative;
  text-align: center;
  margin-top: 1.13rem;
  padding-bottom: 2rem;
  overflow: hidden;
  .door-img{
    width: 7.11rem;
    height: 9.56rem;
  }
}
.activeBlock{
  border: .01rem solid #FFAB0A !important;
}
.scroll-box1{
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 2;
  .scroll-box2{
    .blocks-box{
      width: 2000%;
      transition: transform .5s linear 0s;
      .block{
        width: 6.15rem;
        height: 3.65rem;
        // border: .01rem solid #FFAB0A;
        border: .01rem solid #DCDCDC;
        border-radius: .4rem;
        margin-right: .48rem;
        float: left;
        background: #FFFFFF;
        display: flex;
        align-items: center;
        justify-content: space-around;
        .min-lock-img{
          width: 2.32rem;
          height: 3.15rem;
        }
        .block-right{
          width: 3.2rem;
          height: 3.15rem;
          overflow: hidden;
          position: relative;
          .deco{
            width: 0.79rem;
            height: 0.1rem;
            background: #FFAB0A;
            margin: .39rem 0;
          }
          .go-detail{
            position: absolute;
            color: #FFAB0A;
            font-size: 0.27rem;
            bottom: .05rem;
            right: 0;
            width: 100%;
            height: 1rem;
            line-height: 1.4rem;
            text-align: right;
          }
        }
      }
    }
  }
  
  
}
</style>